// @flow
import React, { useEffect, useState, useRef } from 'react';
import type { AcfFields, Persona } from '../types/personas';
import { Link } from 'react-router-dom';
import CommunitySummaryTileReadMoreLink from './community/CommunitySummaryTileReadMoreLink';
import { debounce } from 'lodash';

type Props = {
  colour: string,
  image: string,
  title: string,
  summary: string,
  communityImageGallery: [
    {
      url: string,
      alt: string,
      title: string,
    }
  ],
  personas: Array<Persona>,
  acf: AcfFields,
  communitySlug: string,
  slug: string,
  hideLinks?: Boolean,
};

const CommunityTile = (props: Props) => {
  const { colour, image, summary, personas, communitySlug, hideLinks } = props;

  const alignPersonas = debounce(() => {
    const textContainers = document.querySelectorAll(
      '.persona-listing-block__text'
    );
    let tallestHeight = 0;

    if (document.documentElement && document.documentElement.style) {
      document.documentElement.style.removeProperty('--text-block-height');

      textContainers.forEach((container) => {
        const containerHeight = container.offsetHeight;
        if (containerHeight > tallestHeight) {
          tallestHeight = containerHeight;
        }
      });

      // $FlowFixMe
      document.documentElement.style.setProperty(
        '--text-block-height',
        tallestHeight + 'px'
      );
    }
  }, 350);

  // make image square
  const [height, setHeight] = useState(0);
  const tileRef = useRef();

  useEffect(() => {
    if (!height && tileRef?.current?.offsetWidth) {
      setHeight(tileRef?.current?.offsetWidth);
    }
  }, [height]);

  useEffect(() => {
    alignPersonas();

    const onResize = () => {
      if (tileRef?.current?.offsetWidth) {
        setHeight(tileRef?.current?.offsetWidth);
      }

      alignPersonas();
    };

    window.addEventListener('resize', onResize);
    return () => {
      window.removeEventListener('resize', onResize);
    };
  });

  return (
    <div className="persona-listing-block__wrapper">
      <div
        className="persona-listing-block__container"
        style={{ borderTop: `3px solid ${colour}` }}
      >
        <div
          ref={tileRef}
          style={{ height: `${height}px` }}
          className="persona-listing-block__image-container"
        >
          <img
            className="persona-listing-block__image"
            src={image}
            alt="community listing"
          />
        </div>
        <div className="persona-listing-block__text">
          <div className="persona-listing-block__heading-text-group">
            <h4>{props?.acf?.personacommunityNumber}</h4>
            <h4>{props?.acf?.title}</h4>
            <div
              dangerouslySetInnerHTML={{ __html: summary }}
              className="persona-listing-block__text-wrapper"
            ></div>
          </div>
          <div
            className="persona-listing-block__button-container"
            style={{ color: colour }}
          >
            {hideLinks ? null : (
              <CommunitySummaryTileReadMoreLink
                text="BROWSE"
                url={props?.communitySlug}
                colour="black"
                name={props?.acf?.title}
                id={props?.acf?.personacommunityNumber}
              />
            )}
          </div>
        </div>
      </div>
      <div className="persona-listing__menu-wrapper">
        <div className="persona-listing-menu">
          {personas &&
            personas
              .slice(0)
              .sort((a, b) => {
                // non-anonymous as you ordered...
                return b.acf.personacommunityNumber <
                  a.acf.personacommunityNumber
                  ? 1 // if b should come earlier, push a to end
                  : b.acf.personacommunityNumber > a.acf.personacommunityNumber
                  ? -1 // if b should come later, push a to begin
                  : 0; // a and b are equal
              })
              .map((persona, key) => (
                <div
                  key={key}
                  className={`persona-listing-menu__persona-link persona-listing-block__modal ${hideLinks ? "link-not-active" : ""}`}
                >
                  {hideLinks ? (
                    <div
                      className="persona-listing-menu__link"
                      style={{ backgroundColor: colour }}
                    >
                      <span className="persona-listing-menu__item">
                        {persona.acf.personacommunityNumber} |{' '}
                        {persona.acf.title}
                      </span>
                    </div>
                  ) : (
                    <Link
                      to={
                        communitySlug +
                        '/' +
                        persona.acf.personacommunityNumber +
                        '-' +
                        persona.acf.title
                          .toLowerCase()
                          .replace(' - ', '-')
                          .replace(' ', '-')
                          .replace(' ', '-')
                          .replace(' ', '-')
                      }
                    >
                      <div
                        className="persona-listing-menu__link"
                        style={{ backgroundColor: colour }}
                      >
                        <span className="persona-listing-menu__item">
                          {persona.acf.personacommunityNumber} |{' '}
                          {persona.acf.title}
                        </span>
                      </div>
                    </Link>
                  )}
                </div>
              ))}
        </div>
      </div>
    </div>
  );
};

export default CommunityTile;
