// @flow
import * as React from 'react';
import ReactHtmlParser from 'html-react-parser';
import Link from '../Link';

type Props = {
  row: any,
};

const CardGrid = ({ row }: Props): any => {

  return (
    <div className="card-grid__wrapper">
      <div className="card-grid__container container">
        {row.heading || row.introText ? (
          <div className="card-grid__intro-group">
            {row.heading && (
              <div className="card-grid__heading">
                <h2>{row.heading}</h2>
              </div>
            )}

            {row.introText && (
              <div className="card-grid__intro-text">
                <p>{row.introText}</p>
              </div>
            )}
          </div>
        ) : null}
        <div className="card-grid__cards-wrapper">
          {row?.cardRow?.map((group, i) => (
            <div className="card-grid__card" key={i}>
              <div className="card-grid__card-content-container">
                <div className="card-grid__card-content-group">
                  {group?.image?.url && (
                    <img src={group?.image?.url} alt={group?.image?.alt}></img>
                  )}
                  {group?.heading && (
                    <h2 className="card-grid__card-heading">
                      {group?.cardHeading}
                    </h2>
                  )}
                  {group?.cardWysiwyg && (
                    <div>{ReactHtmlParser(group?.cardWysiwyg)}</div>
                  )}
                </div>

                {group?.cardLink?.url && (
                  <Link
                    className="card-grid__footer-button button"
                    url={group.cardLink.url}
                    target={group.cardLink.target}
                  >
                    {group.cardLink.title}
                  </Link>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CardGrid;
