// @flow
import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Slider from 'react-slick';
import { withRouter } from 'react-router-dom';
import { fetchPersonas } from '../../actions/personas';
import { getPersonasSortedAlphabetically } from '../../reducers/personas';
import Link from '../Link';

type Props = {
  persona_slug: string,
  row: Object,
};

const HelixPersonaList = ({ persona_slug, row }: Props) => {
  const dispatch = useDispatch();
  const personas = useSelector(getPersonasSortedAlphabetically);
  const sliderRef = useRef(null);
  const [currentSlide, setCurrentSlide] = useState(0);
  const sliderLength = personas?.length;
  const [sliding, setSliding] = useState(false);

  const slideAnimationSpeed = 500;

  const settings = {
    arrows: false,
    className: 'carousel-hero-banner__slider',
    dots: false,
    infinite: true,
    speed: slideAnimationSpeed,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: true,
    beforeChange: (current, next) => {
      setCurrentSlide(next);
    },
    afterChange: (current) => setCurrentSlide(current),
  };

  const handleTimeout = () => {
    setSliding(true);
    setTimeout(() => setSliding(false), slideAnimationSpeed + 100);
  };

  const handleNextSlide = () => {
    if (!sliding) {
      if (sliderRef.current) {
        sliderRef.current.slickNext();
      }

      if (currentSlide === sliderLength - 1) {
        setCurrentSlide(0);
      } else {
        setCurrentSlide(currentSlide + 1);
      }
      handleTimeout();
    }
  };

  const handlePreviousSlide = () => {
    if (!sliding) {
      if (sliderRef.current) {
        sliderRef.current.slickPrev();
      }

      if (currentSlide === 0) {
        setCurrentSlide(sliderLength - 1);
      } else {
        setCurrentSlide(currentSlide - 1);
      }
      handleTimeout();
    }
  };

  const handleGoTo = (i: any) => {
    if (!sliding) {
      if (sliderRef.current) {
        sliderRef.current.slickGoTo(i);
      }
      setCurrentSlide(i);
    }
    handleTimeout();
  };

  useEffect(() => {
    dispatch(fetchPersonas());
  }, [dispatch]);

  return (
    <div className="carousel_hero_banner__wrapper">
      <div className="carousel_hero_banner__grid-container">
        <div className="carousel_hero_banner__content-wrapper">
          <div className="carousel_hero_banner__content">
            {row.heading ? (
              <h1
                className="carousel_hero_banner__heading"
                dangerouslySetInnerHTML={{ __html: row.heading }}
              ></h1>
            ) : null}
            {row.introText ? (
              <div
                className="carousel_hero_banner__wysiwyg"
                dangerouslySetInnerHTML={{ __html: row.introText }}
              ></div>
            ) : null}
            {row.button ? (
              <Link
                url={row?.button?.url}
                className="carousel_hero_banner__cta button"
              >
                {row?.button?.title}
              </Link>
            ) : null}
          </div>
        </div>
        <div className="carousel_hero_banner__carousel-wrapper">
          <div className="carousel_hero_banner__carousel-container">
            <Slider ref={sliderRef} {...settings}>

              {personas?.length && personas?.map((persona, i) => (
                <div className="carousel_hero_banner__slide-wrapper" key={i}>
                  <div className="carousel_hero_banner__slide-container">
                    <div
                      className="carousel_hero_banner__info-square"
                      style={{ backgroundColor: persona?.acf?.color ? persona?.acf?.color : 'transparent' }}
                    >
                      {persona.acf.personacommunityNumber && (
                        <div className="carousel_hero_banner__square-number">
                          {persona.acf.personacommunityNumber}
                        </div>
                      )}

                      {persona.acf.title && (
                        <div className="carousel_hero_banner__square-title">
                          {persona.acf.title}
                        </div>
                      )}
                    </div>
                    {persona?.acf?.featuredImage?.url && (
                      <img
                        className="carousel_hero_banner__image"
                        src={persona?.acf?.featuredImage?.url}
                        alt={persona?.acf?.featuredImage?.alt}
                      />
                    )}
                  </div>
                </div>
              ))}
            </Slider>
          </div>

          {/* controls */}
          {sliderLength > 1 ? (
            <div className={'slider_control_group'}>
              {/* prev button */}
              <button className={`arrow prev`} onClick={handlePreviousSlide}>
                <div className={'arrow_top_line_wrapper'}>
                  <div className={'line'}></div>
                </div>
                <div className={'arrow_bottom_line_wrapper'}>
                  <div className={'line'}></div>
                </div>
              </button>
              <div className={'slider_dots'}>
                {[...Array(sliderLength)].map((e, i) => (
                  <button
                    onClick={() => handleGoTo(i)}
                    className={'slider_dot_wrapper'}
                    key={i}
                  >
                    <div
                      className={`${
                        currentSlide === i ? 'current_slide' : ''
                      } slider_dot`}
                      key={i}
                    ></div>
                  </button>
                ))}
              </div>
              {/* next button */}
              <button className={'arrow'} onClick={handleNextSlide}>
                <div className={'arrow_top_line_wrapper'}>
                  <div className={'line'}></div>
                </div>
                <div className={'arrow_bottom_line_wrapper'}>
                  <div className={'line'}></div>
                </div>
              </button>
            </div>
          ) : null}
        </div>
      </div>
      <div className="carousel_hero_banner__color-lines-wrapper">
        {personas?.length && personas?.map((persona, i) => (
          <div
            className="carousel_hero_banner__color-line"
            style={{ backgroundColor: persona?.acf?.color ? persona?.acf?.color : "transparent" }}
            key={i}
          ></div>
        ))}
      </div>
    </div>
  );
};

export default withRouter(HelixPersonaList);
