// @flow
import {
  REQUEST_PRODUCTS,
  RECEIVE_PRODUCTS,
  REQUEST_PRODUCT,
  RECEIVE_PRODUCT
} from '../actions';
import type { Product, ProductState as State } from '../types';

type Action = {
  +type: string,
  +products?: Array<Product>,
  +product?: Product,
};

const initialState = {
  isFetching: false,
  items: []
};

function products(state: State = initialState, action: Action) {
  switch (action.type) {
    case REQUEST_PRODUCTS:
      return {
        ...state,
        isFetching: true
      };
    case RECEIVE_PRODUCTS:
      return {
        ...state,
        isFetching: false,
        items: action.products
      };
    case REQUEST_PRODUCT:
      return {
        ...state,
        isFetching: true
      };
    case RECEIVE_PRODUCT:
      return {
        ...state,
        isFetching: false,
        items: [
          action.product
        ]
      };
    default:
      return state;
  }
}

export default products;
