// @flow
import { camelizeKeys } from 'humps';
import { noCamelize } from '../helpers';
import type { ThunkAction, Action, Product } from '../types';
import { API_BASE } from '../helpers';

export const REQUEST_PRODUCTS = 'REQUEST_PRODUCTS';
function requestProducts(): Action {
  return {
    type: REQUEST_PRODUCTS
  };
}

export const RECEIVE_PRODUCTS = 'RECEIVE_PRODUCTS';
function receiveProducts(json: Array<Product>): Action {
  return {
    type: RECEIVE_PRODUCTS,
    products: json
  };
}

export function fetchProducts(): ThunkAction {
  return function(dispatch) {
    dispatch(requestProducts());
    return fetch(`${API_BASE}/wp-json/wp/v2/products`)
      .then(
        response => response.json(),
        // eslint-disable-next-line no-console
        error => console.error('An error occured.', error)
      )
      .then(json => {
        const camelizedJson = camelizeKeys(json, noCamelize);
        return dispatch(receiveProducts(camelizedJson));
      });
  };
}

export function fetchNvsProducts(): ThunkAction {
  return function(dispatch) {
    dispatch(requestProducts());
    return fetch(`${API_BASE}/wp-json/wp/v2/products?slug=nvs-landing`)
      .then(
        response => response.json(),
        // eslint-disable-next-line no-console
        error => console.error('An error occured.', error)
      )
      .then(json => {
        const camelizedJson = camelizeKeys(json, noCamelize);
        return dispatch(receiveProducts(camelizedJson));
      });
  };
}

export const REQUEST_PRODUCT = 'REQUEST_PRODUCT';
function requestProduct(): Action {
  return {
    type: REQUEST_PRODUCT
  };
}

export const RECEIVE_PRODUCT = 'RECEIVE_PRODUCT';
function receiveProduct(slug, json): Action {
  return {
    type: RECEIVE_PRODUCT,
    product: json,
    slug
  };
}

// TODO: Handle Product not found.
export function fetchProduct(slug: string): ThunkAction {
  return function(dispatch) {
    dispatch(requestProduct());
    return fetch(`${API_BASE}/wp-json/wp/v2/products?slug=${slug}`)
      .then(
        response => response.json(),
        // eslint-disable-next-line no-console
        error => console.error('An error occured.', error)
      )
      .then(json => {
        const camelizedJson = camelizeKeys(json, noCamelize);
        return dispatch(receiveProduct(slug, camelizedJson[0]));
      });
  };
}
