// @flow
import React from 'react';
import { connect } from 'react-redux';
import { Container } from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { fetchPersonas } from '../actions/personas';
import CommunityTile from './CommunityTile';
import { getPersonasSortedAlphabetically } from '../reducers/personas';
import type { Persona as Personas } from '../types';
import { makeCommunityURL } from '../helpers/makeURL';

type Props = {
  fetchPersonas: () => Promise<void>,
  personas: Array<Personas>,
  persona_slug: string,
  history: any,
  fetchProducts: () => Promise<void>,
  fetchPersonas: () => Promise<void>,
  loadMarketingPage: (slug: string) => Promise<void>,
  products: Array<Object>,
  isFetching: boolean,
  isFetchingPersonas: boolean,
  marketingPage: Object,
  row: Object,
};

class HelixPersonaList extends React.Component<Props> {
  componentDidMount() {
    this.props.fetchPersonas();
  }
  render() {
    const { personas, persona_slug, row } = this.props;

    const personaTiles = personas
      ? personas.map((persona, key) => {
          if (persona && persona.acf) {
            return (
              <CommunityTile
                key={key}
                colour={persona.acf.color}
                communitySlug={makeCommunityURL(
                  persona.slug,
                  persona.acf.title
                )}
                image={persona.acf.featuredImage.url}
                title={persona.postTitle}
                summary={persona.acf.listingSummary}
                communityImageGallery={persona.acf.communityImageGallery}
                personas={persona.personas}
                acf={persona.acf}
                slug={persona_slug}
                hideLinks={row.hideLinks}
              />
            );
          } else {
            return '';
          }
        })
      : [];

    return (
      <div className="helix-persona-list__wrapper">
        <Container>
          {row.title ? (
            <h3
              className="helix-persona-list__heading"
              dangerouslySetInnerHTML={{ __html: row.title }}
            ></h3>
          ) : null}
          <div className="helix-persona-list__grid">{personaTiles}</div>
        </Container>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    personas: getPersonasSortedAlphabetically(state),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchPersonas,
    },
    dispatch
  );

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(HelixPersonaList)
);
