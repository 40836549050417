// @flow

import * as React from 'react';
import type { TwoColumn as ContentType } from '../../types';
import ReactHtmlParser from 'html-react-parser';

type Props = {
  row: ContentType,
};

class TwoColumn extends React.Component<Props> {
  render() {
    const { row } = this.props;
    return (
      <div
        className={`two-column-wrapper ${
          row.nvsStyle ? 'two-column__nvs-style' : ''
        }`}
      >
        <div className="two-column">
          <div
            className={`two-column__grid ${
              row.pullLeftUnderRightOnMobile ? 'reversed' : ''
            }`}
          >
            <div className="two-column__column">
              {row.leftColumn && (
                <div className="body--lead">
                  {ReactHtmlParser(row.leftColumn)}
                </div>
              )}
            </div>
            <div className="two-column__column">
              {row.rightColumn && (
                <div className="body--lead">
                  {ReactHtmlParser(row.rightColumn)}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default TwoColumn;
