// @flow
import * as React from 'react';
import ReactHtmlParser from 'html-react-parser';
import Link from '../Link';

type Props = {
  row: any,
};

const CardGroupRow = ({ row }: Props): any => {
  const handleResize = () => {
    const cardRows = document.querySelectorAll('.card-row-group__card-row');

    if (cardRows?.length) {
      cardRows.forEach((cardRow) => {
        const subheadings = cardRow.querySelectorAll(
          '.card-row-group__subheading'
        );

        let maxHeight = 0;

        if (subheadings?.length) {
          subheadings.forEach((element) => {
            if (element?.clientHeight > maxHeight) {
              maxHeight = element.clientHeight;

              const headingWrappers = cardRow.querySelectorAll(
                '.card-row-group__subheading-wrapper'
              );

              if (headingWrappers?.length) {
                headingWrappers.forEach((headingWrapper) => {
                  headingWrapper.style.height = `${maxHeight}px`;
                });
              }
            }
          });
        }
      });
    }
  };

  React.useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className="card-group-row__wrapper">
      <div className="card-group-row__container container">
        {row.heading || row.introText ? (
          <div className="card-group-row__intro-group">
            {row.heading && (
              <div className="card-group-row__heading">
                <h2>{row.heading}</h2>
              </div>
            )}

            {row.introText && (
              <div className="card-group-row__intro-text">
                <p>{row.introText}</p>
              </div>
            )}
          </div>
        ) : null}

        <div className="card-group-row__cards-wrapper">
          {row.cardGroup.map((group, i) => (
            <div className="card-row-group__cards-placement" key={i}>
              <div className="card-row-group__cards-button-group">
                <div className="card-row-group__cards-container">
                  {group.groupHeading && (
                    <h2 className="card-row-group__card-group-heading">
                      {group.groupHeading}
                    </h2>
                  )}
                  <div className="card-row-group__card-row">
                    {group?.cards?.map((card, index) => (
                      <div className="card-row-group__card" key={index}>
                        <div className="card-row-group__card-heading">
                          <h2>{card?.cardHeading}</h2>
                        </div>
                        <div className={'card-row-group__subheading-wrapper'}>
                          <h3 className="card-row-group__subheading">
                            {card?.cardSubheading}
                          </h3>
                        </div>
                        <div>
                          {card?.cardImage?.url && (
                            <img
                              src={card?.cardImage?.url}
                              alt={card?.cardImage?.alt}
                            />
                          )}
                        </div>
                        {card?.cardContent && (
                          <div className="card-row-group__wysiwyg">
                            {ReactHtmlParser(card.cardContent)}
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                  {group?.footerText && (
                    <h2 className="card-row-group__footer-text">
                      {group.footerText}
                    </h2>
                  )}
                </div>
                {group?.cta?.url && (
                  <Link
                    className="card-row-group__footer-button button"
                    url={group?.cta?.url}
                    target={group?.cta?.target}
                  >
                    {group?.cta?.title}
                  </Link>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CardGroupRow;
