// @flow strict
import React from 'react';
import type { ImageAndContentType } from '../../types';
import ReactHtmlParser from 'html-react-parser';

type Props = {
  row: ImageAndContentType,
};

const ImageAndContent = ({ row }: Props) => {
  return (
    <div className={`image-content__wrapper ${row?.swapPositions ? "reverse" : ""}`}>
      <div className="image-content__container">
        <div className="image-content__grid">
          <div className="image-content__content">
            <h2 className="image-content__heading">{row?.title}</h2>
            <p>{row?.introText}</p>
            {row?.paragraphRepeater &&
              row?.paragraphRepeater.map(function (paragraphItem, i) {
                return (
                  <div key={i} className="image-content__content-block">
                    {paragraphItem?.paragraphHeading ? (
                      <h3 className="image-content__sub-heading">
                        {paragraphItem?.paragraphHeading}
                      </h3>
                    ) : null}

                    {paragraphItem?.paragraphText ? (
                      <div className="image-content__text">
                        {ReactHtmlParser(paragraphItem?.paragraphText)}
                      </div>
                    ) : null}
                  </div>
                );
              })}
          </div>
          <div className="image-content__image-wrapper">
            <img
              className="image-content__image"
              src={row?.image?.url}
              alt="Helix personas"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImageAndContent;
